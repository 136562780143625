import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Stack, Box, Text, Grid, Button } from '@tymate/margaret';
import styled, { createGlobalStyle, css } from 'styled-components';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { motion, useCycle } from 'framer-motion';
import tooltip from 'images/tooltip.svg';
import { Section, Overtitle } from 'ui';
import { navigate } from 'gatsby';
import IntersectionSensor from 'components/IntersectionSensor';

const Gallery = styled(Grid)`
  grid-template-columns: repeat(2, 1fr auto);
  grid-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${({ theme }) => theme.media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const GalleryItemWrapper = styled(Box)`
  position: relative;

  > * {
    height: 100%;
  }

  ${({ orientation }) =>
    orientation === 'landscape' &&
    css`
      grid-column: span 2;
    `}
`;

const GalleryStyles = createGlobalStyle`
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const tooltipVariants = {
  open: () => ({
    clipPath: `circle(800px at 22px 22px)`,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  }),
  closed: {
    clipPath: 'circle(14px at 22px 22px)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const TooltipWrapper = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 44px;
  z-index: 2;
`;

const Tooltip = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Legend = styled(Box)`
  ${({ theme }) => theme.fontStyles.bodySmall}

  color: var(--colors-blue-600);
`;

const GalleryButton = styled(Button)`
  z-index: 10;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  background-color: ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.spacing(0.625)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.button.borderRadius};

  &:hover {
    background-color: var(--colors-blue-500);
  }

  transition: background-color 0.2s ease-in-out;
`;

const GalleryItem = ({ picture }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <GalleryItemWrapper
      orientation={picture.width > picture.height ? 'landscape' : 'portrait'}
      onMouseEnter={() => toggleOpen()}
      onMouseLeave={() => toggleOpen()}
    >
      <TooltipWrapper initial={false} animate={isOpen ? 'open' : 'closed'}>
        <Tooltip variants={tooltipVariants}>
          <Stack padding={0.5} gap={0.5} alignY="center">
            <Stack>
              <img src={tooltip} alt="" />
            </Stack>
            <Legend>{picture?.title}</Legend>
          </Stack>
        </Tooltip>
      </TooltipWrapper>
      <Zoom>
        <GatsbyImage
          image={getImage(picture)}
          alt={picture?.alt || picture?.title || ''}
          style={{ borderRadius: '2px' }}
        />
      </Zoom>
    </GalleryItemWrapper>
  );
};

const GalleryContent = ({ variant, title, overTitle, pics }) => {
  return (
    <>
      <GalleryStyles />
      <Stack
        paddingHorizontal={{ default: 1, tablet: 2, desktop: 4 }}
        gap={{ default: 2, desktop: 6 }}
        direction={{ default: 'column', desktop: 'row' }}
      >
        <Stack
          direction="column"
          gap={0.5}
          flex={{ default: 1, desktop: 2 }}
          alignX="center"
        >
          <Overtitle>{overTitle}</Overtitle>
          <Text type="h2" style={{ whiteSpace: 'pre-wrap' }}>
            {title}
          </Text>
          <Gallery>
            {pics.map(picture => (
              <GalleryItem key={picture.url} picture={picture} />
            ))}
          </Gallery>

          {variant === 'homepage' && (
            <GalleryButton
              onClick={() => {
                navigate('/galerie');
              }}
            >
              Voir la galerie
            </GalleryButton>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const GallerySection = ({ variant, title, overTitle, pics }) => {
  return (
    <Section
      style={{ backgroundColor: '#ffffff' }}
      variant={variant}
      id="photos"
    >
      <Container>
        {variant === 'homepage' ? (
          <IntersectionSensor section="#photos">
            <GalleryContent
              variant={variant}
              title={title}
              overTitle={overTitle}
              pics={pics}
            />
          </IntersectionSensor>
        ) : (
          <GalleryContent
            variant={variant}
            title={title}
            overTitle={overTitle}
            pics={pics}
          />
        )}
      </Container>
    </Section>
  );
};

export default GallerySection;
